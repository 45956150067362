<template>

  <div class="checkout-items">
    <template v-if="!$isEmpty(productInCart)">
      <b-card
        v-for="(inCart, index) in productInCart"
        :key="inCart.key"
        class="ecommerce-card"
        no-body
      >

        <!-- Product Image -->
        <div class="item-img">
          <b-link>
            <b-img
              :src="inCart.product.image"
              :alt="`${inCart.product.product_name}`"
            />
          </b-link>
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body>
          <div class="item-name">
            <h6 class="mb-0">
              <b-link 
                :to="{ name: 'marketplace-details', params: { slug: inCart.product._id } }"
                class="text-body"
              >
                {{ inCart.product.product_name }}
              </b-link>
            </h6>
            <span class="item-company">By <b-link class="company-name">{{ inCart.product.manufactured }}</b-link></span>
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= inCart.product.rating}, star <= inCart.product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>
          </div>
          <span class="text-success mb-1">In Stock</span>
          <div class="item-quantity">
            <span class="quantity-title">Qty:</span>
            <b-form-spinbutton
              v-model="inCart.quantity"
              :disabled="!isEditable"
              size="sm"
              class="ml-75"
              inline
              @change="updateCart(index)"
            />
          </div>
          <span class="delivery-date text-muted">Delivery by {{ formatDate(inCart.shippingDate, { month: 'short', day: 'numeric', weekday: 'short' }) }}</span>
          <span class="text-success">{{ inCart.discountPercentage }}% off {{ inCart.offers }} offers Available</span>
        </b-card-body>

        <!-- Product Options/Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                ${{ inCart.product.price }}
              </h4>
              <p
                v-if="inCart.product.hasFreeShipping"
                class="card-text shipping"
              >
                <b-badge
                  pill
                  variant="light-success"
                >
                  Free Shipping
                </b-badge>
              </p>
            </div>
          </div>
          <b-button
            v-if="isEditable"
            variant="light"
            class="mt-1 remove-wishlist text-danger"
            @click="removeProductFromCartClick(inCart)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span>Remove</span>
          </b-button>
          <b-button
            v-if="isEditable"
            variant="light"
            class="btn-cart move-cart"
            @click="toggleProductInWishlist(inCart)"
          >
            <feather-icon
              icon="HeartIcon"
              class="mr-50"
              :class="{'fill-current': inCart.isInWishlist}"
            />
            <span class="text-nowrap">Wishlist</span>
          </b-button>
        </div>
      </b-card>
    </template>

    <template v-else>
      <div class="d-flex align-items-center justify-content-center h-100">
        <b-row>
          <b-col cols="12" class="text-center">
            <h3>
              No Product(s) in Cart
            </h3>
          </b-col>
          <b-col cols="12" class="mt-2 text-center">
            <b-button
              variant="primary"
              @click="$router.push({name: 'marketplace'})"
            >
              Browse Products
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
} from 'bootstrap-vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
  },
  props: {
    productInCart: {
      type: Array,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      products: [],
    }
  },
  methods: {
    removeProductFromCartClick(inCart) {
      this.$http
        .delete(`/api/product-cart/${inCart._id}`)
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Success',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )

            this.$emit('refresh-cart')
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {
          // this.isLoading = false
        })
    },
    updateCart(index) {
      // TODO: add debounce so that when user click fast it will not submit every click
      const inCart = this.productInCart[index]

      this.$http
        .put(`/api/product-cart/${inCart._id}`, {
          form_data: inCart,
        })
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Success',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )

            this.$emit('refresh-cart')
          } else {
            this.$emit('refresh-cart')

            if (inCart['product']['stock'] < inCart['quantity']) {
              this.productInCart[index]['quantity'] = inCart['product']['stock']
            }

            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {
          // this.isLoading = false
        })
    },
  },
  setup() {
    return {
      // Filter
      formatDate,
    }
  },
}
</script>

<style>

</style>
